
















































































































@import "../styles/vars";
.popover-modal.page-popover.modal-post {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  max-height: calc(var(--win-height, 100vh) - #{$base-padding * 2} - #{$base-size});

  .content-wrap {
    max-height: calc(var(--win-height, 100vh) - #{$base-padding} * 2);
    height: 100%;
  }
  .content{
    width: 100%;
    max-width: 65ch;
    padding: $base-padding;
    //height: calc(var(--win-height, 100vh) - #{$base-padding} * 2);
    max-height: 600px;
    overflow: auto;
    border: solid rgba(0, 0, 0, 0.41);
    border-width: 1px 0;
    background: var(--bgocolor-semi);

   // font-family: #{$font-family-sans-serif};

    .attachment-box {
    }
  }
  .modal-shadow {
    background: transparent;
  }
}
